import { PseudoBox } from '@audentio/stuff/src/PseudoBox';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import ArrowRightIcon from 'mdi-react/ArrowRightIcon';
import React, { useEffect, useState } from 'react';
import { Box, BoxProps } from '../Box';
import { Flex } from '../Flex';
import { IO } from '../IO';
import { CarouselStyles } from './Carousel.styled';

function CarouselArrows({
    swiper,
    ...props
}: Omit<BoxProps, 'swiper'> & { swiper: import('swiper').default }) {
    if (!swiper) return null;
    const disabledColor = '#ccc';

    return (
        <Box {...props} userSelect="none">
            <PseudoBox
                as={ArrowLeftIcon}
                cursor="pointer"
                color={swiper.isBeginning ? disabledColor : 'bodyText'}
                _hover={{
                    color: swiper.isBeginning ? disabledColor : 'boldText',
                }}
                onClick={() => {
                    swiper.slidePrev();
                }}
                size={12}
            />
            <PseudoBox
                as={ArrowRightIcon}
                cursor="pointer"
                color={swiper.isEnd ? disabledColor : 'bodyText'}
                _hover={{
                    color: swiper.isEnd ? disabledColor : 'boldText',
                }}
                onClick={() => {
                    swiper.slideNext();
                }}
                size={12}
            />
        </Box>
    );
}

function CarouselDots({
    numSlides,
    activeIndex,
    swiper,
    ...props
}: Omit<BoxProps, 'numSlides' | 'swiper'> & {
    numSlides: number,
    activeIndex: number,
    swiper: import('swiper').default,
}) {
    const size = '10px';

    if (!swiper) return null;

    return (
        <Box {...props}>
            {swiper.slides.map((slide, index) => (
                <PseudoBox
                    key={index}
                    display="inline-block"
                    mr={3}
                    borderRadius={size}
                    width={size}
                    height={size}
                    backgroundColor={
                        activeIndex === index ? 'boldText' : 'border'
                    }
                    _hover={{
                        cursor: 'pointer',
                        backgroundColor: 'bodyText',
                    }}
                    onClick={() => {
                        swiper.slideTo(index);
                    }}
                />
            ))}
        </Box>
    );
}

type CarouseProps = {
    dots?: boolean,
    arrows?: boolean,
    slideWidth?: number,
    slides?: any[],
    enabled?: boolean,
    [key: string]: any,
}

export function CarouselComponent({
    slides,
    dots = true,
    arrows = true,
    slideWidth,
    enabled = false,
    onSlideChange,
    ...rest
}: CarouseProps) {
    const [swiper, setSwiper] = useState();
    const [activeIndex, setActiveIndex] = useState(0);
    const [SwiperLib, setSwiperLib] = useState(null);

    useEffect(() => {
        if (enabled) {
            import(/* webpackPrefetch: true */'swiper/react').then(module => {
                setSwiperLib({
                    Swiper: module.Swiper,
                    Slide: module.SwiperSlide
                });
            });
        }
    }, [enabled]);

    if (!SwiperLib) {
        return (
            <>
                <CarouselStyles />
                {slides[0]}
            </>
        );
    }

    return (
        <>
            <CarouselStyles />

            <Flex
                // width={slideWidth}
                align="center"
                direction="row"
                mb="spacing-lg"
            >
                {dots && (
                    <CarouselDots
                        justifySelf="start"
                        activeIndex={activeIndex}
                        swiper={swiper}
                        numSlides={3}
                    />
                )}

                {arrows && (
                    <CarouselArrows justifySelf="flex-end" ml="spacing-lg" swiper={swiper} />
                )}
            </Flex>

            <SwiperLib.Swiper
                onSwiper={(swiper: any) => {
                    setSwiper(swiper);
                }}
                onSlideChange={swiper => {
                    setActiveIndex(swiper.activeIndex);
                    if (onSlideChange) onSlideChange(swiper);
                }}
                spaceBetween={50}
                style={{ overflow: 'visible' }}
                width={slideWidth}
                {...rest}
            >
                {slides.map((slide, index) => (
                    <SwiperLib.Slide key={index}>
                        {slide}
                    </SwiperLib.Slide>
                ))}
            </SwiperLib.Swiper>
        </>
    );
}

export const Carousel = (props: CarouseProps) => {
    return (
        <IO>
            {({ hasBeenVisible }) => (
                <CarouselComponent enabled={hasBeenVisible} {...props} />
            )}
        </IO>
    )
}
